jQuery(document).ready(function($){

    /* INITIALISE PLUGINS */

    /* COOKIE POLICY */
    $(document).on('click', '.cookie_policy_pop .button', function(e){
        e.preventDefault();
        $.ajax({
            type: 'POST',
            url: '/process/cookie_policy',
            data: "cookie=1",
            cache: false,
            success: function(response){
                $('.cookie_policy_pop').slideToggle();
            }
        });
    });

    /* HOMEPAGE HEADER */
    $('.header_wrap .placeholder .play_button').bind('click', function() {
        $('.header_wrap .placeholder').slideUp();
        $('.header_wrap .video').slideDown();
    });

    /* HOMEPAGE VIDEO */
    $('.video_cont .video_placeholder .play_button').bind('click', function() {
        $('.video_cont .video_placeholder').slideUp();
        $('.video_cont .video').slideDown();
    });

    /* PLANT LIST */
    $('#plant-list ul.plant_list li .header').bind('click', function() {
        $(this).next('.details').slideToggle();
    });


    // SLICK - Homepage & other slides injected by the site.
    $('.hero-slides').slick({
        arrows:false,
        asNavFor:'.hero-nav',
        autoplay:true,
        autoplaySpeed:5000,
        speed:1000
    });

    $('.hero-nav').slick({
        arrows:false,
        slidesToShow: 6,
        asNavFor:'.hero-slides',
        slide:'li',
        focusOnSelect:true
    });

    $('.page_slider .slides').slick({
        slide:'li',
        dots:true,
        arrows:false,
        autoplay:true
    });
});

var CaptchaCallback = function(){
	var SITE_KEY = '6Lc9EWAUAAAAAHMeiwfw2Y6XvU_U5XQ07OTitpee';

	// Get all form elements on page.
	var pageForms = $('form');

	// Ensure the page has a form on it, at least.
	if(pageForms.length <= 0) {
		return;
	}


	pageForms.each(function(formIncId, form){
		// Grab all of the 'submit' buttons which are attached to the form.
		var submissionButtons = $(form).find('input[type="submit"]:not(.captcha-ignore)');

		if(submissionButtons.length <= 0){
			return; // Return here is similar to 'continue' within a loop.
		}

		submissionButtons.each(function(btnIncId, button){
			$(button).attr('data-sitekey', SITE_KEY);

			grecaptcha.render(button, {
				sitekey: SITE_KEY,
				callback: function(){
					$(button).closest('form').submit();
				}
			});
		});
	});
};